// @ts-nocheck
/* eslint-enable */
import React from 'react';
import Linker from 'app/shared/modules/Linker';
import appleBadge from 'images/apple-badge.svg';
import androidBadge from 'images/android-badge.svg';
import PropTypes from 'prop-types';

/**
 * Anchor tag with support for the Adjust click and impression tracker system
 * @see https://docs.adjust.com
 */
class MobileAppLink extends React.Component {
  static propTypes = {
    tracker: PropTypes.string.isRequired,
    adgroup: PropTypes.string.isRequired,
    device: PropTypes.oneOf(['ios', 'android']).isRequired,
    onClick: PropTypes.func,
    trackViewNow: PropTypes.bool,
    alt: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
    trackViewNow: true,
    alt: '',
  };

  render() {
    const { tracker, adgroup, device, onClick, trackViewNow, children, alt } = this.props;

    const to = `https://app.us.adjust.com/${tracker}?campaign=web&adgroup=${adgroup}&creative=${device}`;
    const src = `https://view.adjust.com/impression/${tracker}?campaign=web&adgroup=${adgroup}&creative=${device}`;
    const badge = device === 'ios' ? appleBadge : androidBadge;

    return (
      <React.Fragment>
        {trackViewNow ? <img height="1" width="1" style={{ display: 'none' }} src={src} alt={alt} /> : null}
        <Linker to={to} onClick={onClick}>
          {children ? (
            children
          ) : (
            <img
              src={badge}
              alt={device === 'ios' ? 'Image of the Apple App Store badge' : 'Image of the Google Play Store badge'}
              width="120px"
              height="35px"
            />
          )}
        </Linker>
      </React.Fragment>
    );
  }
}

export default MobileAppLink;
