// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import styled from 'styled-components';
import { padding, stacked } from 'app/shared/styles/_spacing';
import { yieldCallback } from '@zillow/yield-callback';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';

// Components
import Center from 'app/shared/modules/Center';
import Container from 'app/shared/core/Container';
import CrossbrandFooter from 'app/shared/modules/navigation/footer/CrossbrandFooter';
import Linker from 'app/shared/modules/Linker';
import MobileAppLink from 'app/shared/modules/mobile-apps/MobileAppLink';
import Section from 'app/shared/modules/Section';
import Text from 'app/shared/core/Text';

// Misc / Utils
import 'app/shared/modules/navigation/footer/FooterNav.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import IconHud from 'images/icons/hud.svg';
import pathsManifest from 'app/shared/utils/pathsManifest';
import queryUtils from 'app/shared/utils/queryUtils';

const StyledCrossbrandSection = styled.section`
  ${stacked._1x};
  ${padding.top._1x};
`;

const StyledFooterNavList = styled.ul`
  ${stacked._1x};
  ${padding.top._1x};
`;

const StyledTextSection = styled.section`
  ${stacked._xs};
  ${padding.top._1x};
`;
const StyledHotPadsSectionContainer = StyledCrossbrandSection;
const StyledFairHousingSection = styled.section`
  ${stacked._1x};
`;
class FooterNav extends React.Component {
  constructor(props) {
    super(props);
  }

  showModal = yieldCallback((type) => {
    const { dispatch } = this.props;

    if (type === 'feedback') {
      this.trackClick('SendFeedbackModal');
      dispatch(AppActions.showPopupModal('PromptFeedback'));
    }
  });

  yieldOpenOptanonPreferences = yieldCallback(() => {
    Optanon.ToggleInfoDisplay();
  });

  handleOptanonClick = () => {
    this.yieldOpenOptanonPreferences();
  };

  trackClick = yieldCallback((pageClickingTo) => {
    this.props.dispatch(
      analyticsEvent(gaEvents.FOOTER_NAV_CLICK_TO, {
        action: pageClickingTo,
      }),
    );
  });

  yieldTrackAndTransitionTo = yieldCallback((path, actionName) => {
    this.trackClick(actionName);
    window.router.transitionTo(path);
  });

  handleLinkClick = (e, { path, actionName, isExternalUrl = false }) => {
    if (isExternalUrl) {
      this.trackClick(actionName);
    } else {
      // Prevent the browser from going through with its default <a> behavior
      e.preventDefault();

      this.yieldTrackAndTransitionTo(path, actionName);
    }
  };

  render() {
    const { noMarginTop, crossbrand, hideFooter } = this.props;

    if (hideFooter) {
      return null;
    }

    return (
      <footer
        className={cx('FooterNav', {
          'FooterNav-no-margin-top': noMarginTop,
        })}
      >
        <Container>
          <Section>
            <Center>
              {crossbrand && (
                <StyledCrossbrandSection>
                  <CrossbrandFooter />
                </StyledCrossbrandSection>
              )}
              <StyledFooterNavList className="FooterNav-unordered-list">
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={(e) =>
                      this.handleLinkClick(e, {
                        path: pathsManifest.ApartmentsNearMePage,
                        actionName: 'apartmentsNearMePage',
                      })
                    }
                    to={pathsManifest.ApartmentsNearMePage}
                    escape
                  >
                    Apartments near me
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={(e) =>
                      this.handleLinkClick(e, {
                        path: pathsManifest.HousesNearMe,
                        actionName: 'housesNearMePage',
                      })
                    }
                    to={pathsManifest.HousesNearMe}
                    escape
                  >
                    Houses near me
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={(e) =>
                      this.handleLinkClick(e, {
                        path: pathsManifest.AboutUsPage,
                        actionName: 'AboutUsPage',
                      })
                    }
                    to={pathsManifest.AboutUsPage}
                  >
                    About us
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={(e) =>
                      this.handleLinkClick(e, {
                        actionName: 'termsOfUse',
                        isExternalUrl: true,
                      })
                    }
                    to={pathsManifest.terms}
                  >
                    Terms of use
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={(e) =>
                      this.handleLinkClick(e, {
                        actionName: 'privacyPolicy',
                        isExternalUrl: true,
                      })
                    }
                    to={pathsManifest.ccpaPortal}
                  >
                    Privacy Portal
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  {/* OneTrust needs this className 'optanon-toggle-display' on this div */}
                  <Linker
                    className="FooterNav-link optanon-toggle-display"
                    linkType="inverted"
                    useButton
                    onClick={this.handleOptanonClick}
                  >
                    Cookie Preference
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={(e) => this.handleLinkClick(e, { isExternalUrl: true, actionName: 'help' })}
                    to={pathsManifest.help}
                  >
                    Help
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={(e) =>
                      this.handleLinkClick(e, {
                        isExternalUrl: true,
                        actionName: 'FeedsPage',
                      })
                    }
                    to={pathsManifest.feedsPage}
                  >
                    Feeds
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={(e) =>
                      this.handleLinkClick(e, {
                        path: pathsManifest.PostAListingPage,
                        actionName: 'PostAListingPage',
                      })
                    }
                    to={pathsManifest.PostAListingPage}
                  >
                    List your rental
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={(e) =>
                      this.handleLinkClick(e, {
                        path: pathsManifest.SitemapPage,
                        actionName: 'SitemapPage',
                      })
                    }
                    to={pathsManifest.SitemapPage}
                  >
                    Sitemap
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted"
                    onClick={() => this.showModal('feedback')}
                    useButton
                  >
                    Send feedback
                  </Linker>
                </li>
                <li className="FooterNav-list-item">
                  <Linker
                    className="FooterNav-link"
                    linkType="inverted-teal"
                    onClick={(e) =>
                      this.handleLinkClick(e, {
                        actionName: 'doNotSellMyInfo',
                        isExternalUrl: true,
                      })
                    }
                    to={pathsManifest.zgCcpaCookiePage}
                  >
                    Do Not Sell or Share My Personal Information
                  </Linker>
                </li>
              </StyledFooterNavList>
              <StyledFooterNavList className="FooterNav-mobile-apps">
                <li className="FooterNav-list-item">
                  <MobileAppLink
                    tracker="sbfwuc"
                    adgroup="homepageFooter"
                    device="ios"
                    onClick={() => this.trackClick('iosApp')}
                    alt="Download on the App Store"
                  />
                </li>
                <li className="FooterNav-list-item">
                  <MobileAppLink
                    tracker="nuf8sz"
                    adgroup="homepageFooter"
                    device="android"
                    onClick={() => this.trackClick('androidApp')}
                    alt="Get it on Google Play"
                  />
                </li>
              </StyledFooterNavList>
              <StyledTextSection>
                <Text size="tiny">
                  Zillow Group is committed to ensuring digital accessibility for individuals with disabilities. We are
                  continuously working to improve the accessibility of our web experience for everyone, and welcome
                  feedback and accommodation requests. If you wish to report an issue or seek an accommodation please
                  contact us at
                  <Linker to="mailto:support@hotpads.com" linkType="inverted-teal">
                    {' '}
                    support@hotpads.com
                  </Linker>
                </Text>
              </StyledTextSection>
              <StyledTextSection>
                <Linker linkType="inverted" to={pathsManifest.hud} target="_blank" rel="noopener">
                  <Text size="tiny">
                    Fair Housing and Equal Opportunity
                    {'   '}
                    <img
                      src={IconHud}
                      className="FooterNav-hud-icon"
                      height="16px"
                      width="16px"
                      alt="United States Department of Housing and Urban Development badge"
                      loading="lazy"
                    />
                  </Text>
                </Linker>
              </StyledTextSection>
              <StyledFairHousingSection>
                <Text size="tiny">
                  <Linker linkType="inherit" to={pathsManifest.fairHousingGuide} target="_blank" rel="noopener">
                    Fair Housing Guide
                  </Linker>
                </Text>
              </StyledFairHousingSection>
              <StyledHotPadsSectionContainer>
                <Text size="tiny">© {new Date().getFullYear()} HotPads</Text>
              </StyledHotPadsSectionContainer>
            </Center>
          </Section>
        </Container>
      </footer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceType: state.app.device.deviceType,
    hideFooter: queryUtils.parse(ownProps.location.search).hostApp,
  };
};

export default withRouter(connect(mapStateToProps)(FooterNav));
